.gridcontainer-dashboard { /*screen size: mobile*/
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
    column-gap: 15px;
    row-gap: 15px;
    font-size: 10px;
    color: #a4a4a4;
}

.griditem-white-bg {
    background-color: white;
    padding: 1em;
}

.griditem-dash1 {
    min-width: 0px;
    grid-column: 1;
    grid-row: 1;
}

.griditem-dash2 {
    grid-column: 1;
    grid-row: 2;

}
.griditem-dash3 {
    grid-column: 1;
    grid-row: 3;

}

.griditem-dash4 {
    grid-column: 1;
    grid-row: 4;
    
}

.griditem-dash5 {
    grid-column: 1;
    grid-row: 5;
}

.griditem-dash6 {
    grid-column: 1;
    grid-row: 6;
}

.griditem-dash7 {
    grid-column: 1;
    grid-row: 7;
}

  @media (min-width: 650px) { /* screensize tablet*/
    .gridcontainer-dashboard { grid-template-columns: 1fr;}
    .griditem-dash1 { grid-column: 1; grid-row: 1;}
    .griditem-dash2 { grid-column: 2; grid-row: 1;}
    .griditem-dash3 { grid-column: 1; grid-row: 2;}
    .griditem-dash4 { grid-column: 2; grid-row: 2;}
    .griditem-dash5 { grid-column: 1; grid-row: 3;}
    .griditem-dash6 { grid-column: 2; grid-row: 3;}
    .griditem-dash7 { grid-column: 1; grid-row: 4;}
  }

@media (min-width: 1150px) { /* screensize laptop*/
    .gridcontainer-dashboard { grid-template-columns: repeat(4, 1fr); grid-template-rows: repeat(3, 1fr);}
    .griditem-dash1 { grid-column: 1; grid-row: 1;}
    .griditem-dash2 { grid-column: 2; grid-row: 1;}
    .griditem-dash3 { grid-column: 3; grid-row: 1;}
    .griditem-dash4 { grid-column: 4; grid-row: 1;}
    .griditem-dash5 { grid-column: 1; grid-row: 2 / span 2}
    .griditem-dash6 { grid-column: 2; grid-row: 2 / span 2}
    .griditem-dash7 { grid-column: 3; grid-row: 2 / span 2}
  }

.gridcontainer-dash1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, 1fr);
    font-size: 10px;
    color: #a4a4a4;
    height: 155px;
}
.griditem-dash1-1 {grid-column: 1 / span 2; grid-row: 1 / span 8}
.griditem-dash1-2 {grid-column: 1 / span 2; grid-row: 9}
.griditem-dash1-3 {grid-column: 1; grid-row: 10}
.griditem-dash1-4 {grid-column: 2; grid-row: 10}

.gridcontainer-dash2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, 1fr);
    font-size: 10px;
    color: #a4a4a4;
    height: 155px;
}
.griditem-dash2-1 {grid-column: 1 / span 2; grid-row: 1 / span 9; overflow: hidden}
.griditem-dash2-2 {grid-column: 1 / span 2; grid-row: 10}

.gridcontainer-dash3 {
    
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(10, 1fr);
    font-size: 10px;
    color: #a4a4a4;
    height: 155px;
}
.griditem-dash3-1-1 {grid-column: 1; grid-row: 1 / span 4; place-self: center;}
.griditem-dash3-1-2 {grid-column: 2; grid-row: 1 / span 4; place-self: center;}
.griditem-dash3-1-3 {grid-column: 3; grid-row: 1 / span 4; place-self: center;}
.griditem-dash3-1-4 {grid-column: 4; grid-row: 1 / span 4; place-self: center}
.griditem-dash3-2-1 {grid-column: 1; grid-row: 5; place-self: center;}
.griditem-dash3-2-2 {grid-column: 2; grid-row: 5; place-self: center;}
.griditem-dash3-2-3 {grid-column: 3; grid-row: 5; place-self: center;}
.griditem-dash3-2-4 {grid-column: 4; grid-row: 5; place-self: center}
.griditem-dash3-3-1 {grid-column: 1 / span 4; grid-row: 8}
.griditem-dash3-4-1 {grid-column: 1 / span 4; grid-row: 9}
.griditem-dash3-5-1 {grid-column: 1 / span 4; grid-row: 10}
