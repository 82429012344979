.ant-input {
  border-radius: 0 !important;
}

.ant-select .ant-select-selector {
  border-radius: 0 !important;
}

.ant-btn {
  border-radius: 0 !important;
}

.add-manual-event {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}