.dashboard--column {
  display: block;
  background-color: white;
  padding: 1em;
}

.dashboard--status-message {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}

.dashboard--status-message .dashboard--status-message--editor {
  margin-bottom: 1em;
}

.dashboard--status-message .dashboard--status-message--title {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.dashboard--status-message .dashboard--status-message--title span {
  display: inline-block;
  margin-left: 1em;
  cursor: pointer;
}

.dashboard--status-message .dashboard--status-message--title span:hover {
  color: blue;
}