.h3{
    font-weight: 600; 
    font-size: 15px; 
    color:rgba(0, 0, 0, 0.85)
}

.h4{
    font-weight: 400; 
    font-size: 10px; 
    color: #a4a4a4;
}

.tab{
    padding: 20px;
    margin: 5px;    
}

.table{
    color: gray;
}

.flex-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.flex-container-inner{
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 2em;
    padding-bottom: 2em;
}

.flex-item-half { 
    flex: 0 0 50%;
}

.flex-item-right{
    height:100vh;
    flex: 1 0 50%;
    overflow: hidden;
}

.authenticator {
    position:relative;
    left: 140px;
    top: 120px;
}

.login-logo{
    width:300px;
    padding-bottom:3em;
}

.tableImage{
    width: 250px;
    height: 90px;
}

.logo {
    margin: 17px;   
}

.ant-menu {
    background-color: #000000 !important;  
}

.ant-menu-dark .ant-menu-item-group-title { 
    color: white;
}