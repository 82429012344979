.create-equipment--outer {
  display: block;
  margin-top: 0.5em;
}

.create-equipment--outer .create-equipment--inner {
  display: block;
}

.create-equipment--outer .create-equipment--inner .create-equipment--form {
  display: block;
  font-weight: bold;
}

.create-equipment--row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 1px;
}

.create-equipment--row .create-equipment--col {
  display: block;
}

.create-equipment--row .create-equipment--col .ant-input {
  border-radius: 0 !important;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  padding: 2px 5px;
}

.create-equipment--row .create-equipment--col .ant-input:focus {
  outline: none;
  border: 1px solid #ccc;
}

.create-equipment--row .create-equipment--col.create-equipment--col-grow {
  flex: 1;
}

.create-equipment--row .create-equipment--col.create-equipment--col-spacer {
  width: 42px;
  display: block;
  text-align: center;
  padding-top: 2px;
}