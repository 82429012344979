.equipmentTable--Outer {
  display: block;
  width: 100%;
  height: auto;
  overflow-x: auto;
}

.equipmentTable--Outer .equipmentTable--Inner {
  display: block;
  height: auto;
  width: auto;
}

.ant-table-expanded-row {
  background-color: white !important;
}

.name-column {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.name-column .name-column--delete-icon {
  display: none;
  width: 25px;
  text-align: center;
  color: #999;
  cursor: pointer;
}

.name-column:hover .name-column--delete-icon {
  display: block;
}

.name-column .name-column--delete-icon:hover {
  color: red;
}