.graph {
    border: 0
}

.gridcontainer-diagnose { /*screen size: mobile*/
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
    column-gap: 15px;
    row-gap: 15px;
    font-size: 10px;
    color: #a4a4a4;
}

.griditem-diagnose1 {
    grid-column: 1;
    grid-row: 1;
}

.griditem-diagnose2 {
    grid-column: 1;
    grid-row: 2;

}
.griditem-diagnose3 {
    grid-column: 1;
    grid-row: 3;

}

.griditem-diagnose4 {
    grid-column: 1;
    grid-row: 4;
    
}

.griditem-diagnose5 {
    grid-column: 1;
    grid-row: 5;
}

.griditem-diagnose6 {
    grid-column: 1;
    grid-row: 6;
}

.griditem-diagnose7 {
    grid-column: 1;
    grid-row: 7;
}

  @media (min-width: 650px) { /* screensize tablet*/
    .gridcontainer-diagnose { grid-template-columns: 1fr;}
    .griditem-diagnose1 { grid-column: 1; grid-row: 1;}
    .griditem-diagnose2 { grid-column: 2; grid-row: 1;}
    .griditem-diagnose3 { grid-column: 1; grid-row: 2;}
    .griditem-diagnose4 { grid-column: 2; grid-row: 2;}
    .griditem-diagnose5 { grid-column: 1; grid-row: 3;}
    .griditem-diagnose6 { grid-column: 2; grid-row: 3;}
    .griditem-diagnose7 { grid-column: 1; grid-row: 4;}
  }

@media (min-width: 1150px) { /* screensize laptop*/
    .gridcontainer-diagnose { grid-template-columns: repeat(6, 1fr); grid-template-rows: repeat(3, 1fr); margin-top: 20px; gap: 20px 20px;}
    .griditem-diagnose1 { grid-column: 1 / span 3; grid-row: 1}
    .griditem-diagnose2 { grid-column: 4 / span 3; grid-row: 1}
    .griditem-diagnose3 { grid-column: 1 / span 3; grid-row: 2}
    .griditem-diagnose4 { grid-column: 4 / span 3; grid-row: 2}
    .griditem-diagnose5 { grid-column: 1 / span 3; grid-row: 3}
    .griditem-diagnose6 { grid-column: 4 / span 3; grid-row: 3}
  }