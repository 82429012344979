.manual-events-table {
  display: block;
  margin-right: 1em;
}

.manual-events-table--add-event {
  display: block;
  text-align: right;
  cursor: pointer;
}

.manual-events-table--add-event:hover {
  color: green;
}

.manual-events--event {
  display: block;
  margin: 0.25em 0 0 0;
  background-color: #fff;
  border: 1px solid white;
}

.manual-events--event--time {
  display: block;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
}

.manual-events--event--time:after {
  content: '[x]';
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  text-align: center;
}

.manual-events--event--time:hover:after {
  display: block;
}

.manual-events--event.manual-events--event--warning {
  background-color: #fbf8df;
  border-color: #fbf8df;
}

.manual-events--event.manual-events--event--danger {
  background-color: #ffeceb;
  border-color: #ffeceb;
}

.manual-events--event.manual-events--event--warning .manual-events--event--time:after {
  background-color: #fbf8df;
}

.manual-events--event.manual-events--event--danger .manual-events--event--time:after {
  background-color: #ffeceb;
}

.manual-events--event .manual-events--event--inner {
  display: block;
  margin: 1em;
}