.jobs {
  display: block;
  margin-right: 1em;
  color: black !important;
}

.jobs h1 {
  color: black;
}

.jobs--add-job {
  display: block;
  text-align: right;
  cursor: pointer;
}

.jobs--add-job:hover {
  color: green;
}

.jobs--job {
  display: block;
}

.jobs--job .jobs--job--inner {
  display: block;
  background-color: #f8f8f8;
  border: 1px solid white;
  padding: 1em;
}

.jobs--job .jobs--job--inner:hover {
  cursor: pointer;
  background-color: #e7e7e7;
}

.jobs--job--children {
  display: block;
  background-color: white;
  padding-left: 2em;
}

.jobs--job--icons {
  text-align: right;
}

.jobs--job--add {
  padding: 0.5em 0;
  border-bottom: 1px solid #e7e7e7;
}

.create-job .create-job--inner {
  display: block;
  background-color: #e4fdde;
  border: 1px solid white;
  padding: 1em;
}