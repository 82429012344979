.divFleetList{
    padding: 20px;
    margin: 5px;
    margin-top: 50px;
    font-weight: 400; 
    font-size: 10px; 
    color: #a4a4a4;
}

.gridcontainer-fleet { /*screen size: mobile*/
    display: grid;
    grid-template-columns: fit-content(350px);
    grid-template-rows: fit-content(repeat(7, 1fr));
}

.griditem-fleet1 {
    grid-column: 1;
    grid-row: 1;
    justify-self: start;
}

.griditem-fleet2 {
    grid-column: 1;
    grid-row: 2;
}
.griditem-fleet3 {
    grid-column: 1;
    grid-row: 3;

}

.griditem-fleet4 {
    grid-column: 1;
    grid-row: 4;
}

.griditem-fleet5 {
    grid-column: 1;
    grid-row: 5;
}

.griditem-fleet6 {
    grid-column: 1;
    grid-row: 6;
}

.griditem-fleet7 {
    grid-column: 1;
    grid-row: 7;
}

.gridcontainer-fleet1 {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    grid-template-rows: fit-content(repeat(30, 1fr));
}

.griditem-fleet1-1 {grid-column: 1 / span 8; grid-row: 1 / span 10; place-self: start;}
.griditem-fleet1-2-1 {grid-column: 11 / span 5; grid-row: 1 / span 4; align-self: center; font-weight: 600; font-size: 15px; color:rgba(0, 0, 0, 0.85)}
.griditem-fleet1-2-2 {grid-column: 11 / span 5; grid-row: 5 / span 2;}
.griditem-fleet1-2-3 {grid-column: 11 / span 5; grid-row: 7 / span 2;}
.griditem-fleet1-2-4 {grid-column: 11 / span 5; grid-row: 9 / span 2;}
.griditem-fleet1-3-1 {grid-column: 2 / span 2; grid-row: 11 / span 8; place-self: center;}
.griditem-fleet1-3-2 {grid-column: 4 / span 2; grid-row: 11 / span 8; place-self: center;}
.griditem-fleet1-3-3 {grid-column: 6 / span 2; grid-row: 11 / span 8; place-self: center;}
.griditem-fleet1-3-4 {grid-column: 8 / span 2; grid-row: 11 / span 8; place-self: center;}
.griditem-fleet1-3-5 {grid-column: 2 / span 2; grid-row: 19; place-self: center;}
.griditem-fleet1-3-6 {grid-column: 4 / span 2; grid-row: 19; place-self: center;}
.griditem-fleet1-3-7 {grid-column: 6 / span 2; grid-row: 19; place-self: center;}
.griditem-fleet1-3-8 {grid-column: 8 / span 2; grid-row: 19; place-self: center;}


@media (min-width: 650px) { /* screensize tablet*/
    /*same as mobile*/
  }

  @media (min-width: 1150px) { /* screensize laptop*/
    .gridcontainer-fleet { /*screen size: mobile*/
        grid-template-columns: fit-content(730px);
        grid-template-rows: fit-content(repeat(7, 1fr));
    }
   
    .gridcontainer-fleet1 {
        grid-template-columns: repeat(30, 1fr);
        grid-template-rows: fit-content(repeat(10, 1fr));
    }

    .griditem-fleet1-1 {grid-column: 1 / span 8; grid-row: 1 / span 10; place-self: center;}
    .griditem-fleet1-2-1 {grid-column: 10 / span 5; grid-row: 1 / span 4; align-self: center;}
    .griditem-fleet1-2-2 {grid-column: 10 / span 5; grid-row: 5 / span 2;}  
    .griditem-fleet1-2-3 {grid-column: 10 / span 5; grid-row: 7 / span 2;}
    .griditem-fleet1-2-4 {grid-column: 10 / span 5; grid-row: 9 / span 2;}
    .griditem-fleet1-3-1 {grid-column: 16 / span 2; grid-row: 1 / span 8; place-self: center;}
    .griditem-fleet1-3-2 {grid-column: 18 / span 2; grid-row: 1 / span 8; place-self: center;}
    .griditem-fleet1-3-3 {grid-column: 20 / span 2; grid-row: 1 / span 8; place-self: center;}
    .griditem-fleet1-3-4 {grid-column: 22 / span 2; grid-row: 1 / span 8; place-self: center;}
    .griditem-fleet1-3-5 {grid-column: 16 / span 2; grid-row: 9; place-self: center;}
    .griditem-fleet1-3-6 {grid-column: 18 / span 2; grid-row: 9; place-self: center;}
    .griditem-fleet1-3-7 {grid-column: 20 / span 2; grid-row: 9; place-self: center;}
    .griditem-fleet1-3-8 {grid-column: 22 / span 2; grid-row: 9; place-self: center;}

  }